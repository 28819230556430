import "../styles/main.scss";
import { store } from "../store";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import Companies from "../screen/Companies";
import CompanyJobs from "../screen/CompanyJobs";
import LayoutCompany from "../components/LayoutCompany";
import AdminDashboard from "../screen/AdminDashboard";
import { memo, useEffect, useState } from "react";
import DashboardLoaderImg from '../image/dashboard-loader.svg';
import CandidateDashboard from "../screen/CandidateDashboard";
import DomainAssessment from "../screen/DomainAssessment";
import JobsScreen from "../screen/JobsScreen";
import CandidateOffers from "../screen/CandidateOffers";
import CandidateOfferView from "../screen/CandidateOfferView";
import SeeApplicationScreen from "../screen/ApplicationForm";
import CandidateScoreboard from "../screen/CandidateScoreboard";
import CandidateJobScoreboard from "../screen/CandidateJobScoreboard";
import CandidateAnswerSheet from "../screen/CandidateAnswerSheet";
import OffersSign from '../../globalstyle/screen/OffersSign';
import FullCalendarView from "../screen/FullCalendarView";
import { EditorModals } from "../components/candidate-assessment/Popups/editor-modals";
import { useHasPermission, permissionRoutesType, UnAuthorizePageComponent, NotFoundPageComponent, useSubscription } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";
import AboutCompany from "../screen/AboutCompany";

const DisplayCurrentDashboard = memo(({ name }: { name: string }) => {
  return (
    <>
      {name === "admin" && <AdminDashboard />}
      {name === "recruiter" && <AdminDashboard />}
      {name === "candidate" && <CandidateDashboard />}
    </>
  );
});

const candidateCompanyRoutes: Array<permissionRoutesType> = [
  {
    path: "/candidate/meeting-appointment",
    element: <FullCalendarView />,
    module: ['candidate', 'recruiter'],
    permissions: ''
  },
];
const candidateRoutes: Array<permissionRoutesType> = [
  {
    path: "/candidate/companies",
    element: <Companies />,
    module: 'candidate',
    permissions: ""
  },
  {
    path: "/candidate/jobs",
    element: <JobsScreen />,
    module: 'candidate',
    permissions: ""
  },
  {
    path: "/candidate/jobs/:jobId/:companyId/application-form",
    element: <SeeApplicationScreen />,
    module: 'candidate',
    permissions: ""
  },
  {
    path: "/candidate/offers",
    element: <CandidateOffers />,
    module: 'candidate',
    permissions: ""
  },
  {
    path: "/candidate/scoreboard",
    element: <CandidateScoreboard />,
    module: 'candidate',
    permissions: ""
  },
  {
    path: "/candidate/scoreboard/:jobId",
    element: <CandidateJobScoreboard />,
    module: 'candidate',
    permissions: ""
  },
  {
    path: "/candidate/scoreboard/:jobId/domain-assessment/view/:domainId",
    element: <CandidateAnswerSheet />,
    module: 'candidate',
    permissions: ""
  },
  {
    path: "/candidate/offers/view/:offerId",
    element: <CandidateOfferView />,
    module: 'candidate',
    permissions: ""
  },
  {
    path: "/candidate/offers/sign/:offerId",
    element: <OffersSign callbackUrl="/candidate/offers" />,
    module: 'candidate',
    permissions: ""
  },
  {
    path: "/candidate/domain-assessment/:jobId/:assessmentId",
    element: <DomainAssessment />,
    module: 'candidate',
    permissions: ""
  },

]

export default function Root() {
  const { checkUserPermission, onAuthorizedRoutes, companyId } = useHasPermission();
  const { isLoadingSubscription, hasCompanySubscriptionElement, checkCurrentPackageType } = companyId && useSubscription();
  const [isFirstLogin, setIsFirstLogin] = useState(true)
  const [loaderClass, setLoaderClass] = useState("");

  let currentRole = ''
  if (checkUserPermission('candidate')) {
    currentRole = 'candidate'
  } else if (checkUserPermission('company-admin')) {
    currentRole = 'admin'
  } else if (checkUserPermission('recruiter')) {
    currentRole = 'recruiter'
  }

  useEffect(() => {
    const firstLogin = localStorage.getItem('firstLogin');

    if (firstLogin === 'true') {
      setLoaderClass('fade-in');

      const timer = setTimeout(() => {
        setLoaderClass('fade-out'); 
        localStorage.setItem('firstLogin', 'false');
        setIsFirstLogin(false); 
        window.location.reload();
      }, 5000);

      return () => clearTimeout(timer);
    } else {
      setIsFirstLogin(false);
      setLoaderClass('');
    }
  }, []);


  if (isFirstLogin) {
    return (
      <div className={`dashboard__loader ${loaderClass}`}>
        <img src={DashboardLoaderImg} alt="dashboard loading" />
        <h2>Please wait a moment...</h2>
      </div>
    );
  }

  return (
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={companyId ? checkCurrentPackageType('assessment') ? <Navigate to={'/recruitment/assessments'} /> : hasCompanySubscriptionElement(<DisplayCurrentDashboard name={currentRole} />) : <DisplayCurrentDashboard name={currentRole} />} />
          <Route
            path="/candidate/company/:tenantId"
            element={onAuthorizedRoutes('candidate') ? <LayoutCompany /> : <UnAuthorizePageComponent />}>
            <Route index element={onAuthorizedRoutes('candidate') ? <AboutCompany /> : <UnAuthorizePageComponent />} />
            <Route path="opened-jobs" element={onAuthorizedRoutes('candidate') ? <CompanyJobs /> : <UnAuthorizePageComponent />} />
          </Route>
          {!companyId && candidateRoutes.map(route => {
            return <Route path={route.path} key={route.path} element={onAuthorizedRoutes(route.module, route.permissions) ? route.element : <UnAuthorizePageComponent />} />
          })}
          {!companyId ? candidateCompanyRoutes.map(route => {
            return <Route path={route.path} key={route.path} element={onAuthorizedRoutes(route.module, route.permissions) ? route.element : <UnAuthorizePageComponent />} />
          }) : candidateCompanyRoutes.map(route => {
            return <Route path={route.path} key={route.path} element={hasCompanySubscriptionElement(onAuthorizedRoutes(route.module, route.permissions) ? route.element : <UnAuthorizePageComponent />)} />
          })
          }
          <Route path="/candidate/*" element={companyId && isLoadingSubscription ? <h1>Loading...</h1> : <NotFoundPageComponent />} />
        </Routes>
      </BrowserRouter>
      <EditorModals />
    </Provider>
  );
}
