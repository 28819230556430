import { memo, useEffect, useState } from 'react';


const MultiSelectListItem = ({ item, setInputValue, onSelect, selectedSkills }) => {
  const [active, setActive] = useState(false)

  const onClickHandler = () => {
    if (!(selectedSkills.find(x => x === item.name))) {
      setActive(!active)
      onSelect([...selectedSkills, item.name])
    } else {
      setActive(false)
      onSelect(selectedSkills.filter(x => x !== item.name))
    }
    setInputValue('')
  }

  useEffect(() => {
    if (selectedSkills.find(x => x === item.name)) {
      setActive(true)
    } else {
      setActive(false)
    }
  }, [selectedSkills])

  return (
    <div
      className={`multi-select__list__item ${active ? 'active' : ''}`}
      onClick={onClickHandler}
      role="button"
      tabIndex={0}
    >
      {item.name}
    </div>
  )
}

export default memo(MultiSelectListItem)